<script>
//Importing Line class from the vue-chartjs wrapper
import { Doughnut } from "vue-chartjs";

//Exporting this so it can be used in other components
export default {
  props: {
    labels: Array,
    datasets: Array
  },
  extends: Doughnut,
  mounted() {
    const gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    const gradient2 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    const gradient3 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    gradient.addColorStop(1, "rgba(135, 218, 252, 1)");
    gradient.addColorStop(0, "rgba(39, 172, 226, 1)");

    gradient2.addColorStop(1, "rgba(255, 173, 202, 1)");
    gradient2.addColorStop(0, "rgba(255, 13, 100, 1)");

    gradient3.addColorStop(1, "rgba(255, 215, 150, 1)");
    gradient3.addColorStop(0, "rgba(255, 161, 10, 1)");

    this.renderChart(
      {
        labels: this.labels || ["Kelas 1", "Kelas 2", "Kelas 3"],
        datasets: this.datasets || [
          {
            fill: true,
            backgroundColor: [gradient, gradient2, gradient3],
            data: [40, 22, 30]
          }
        ]
      },
      {
        legend: {
          display: true,
          position: "bottom"
        },
        tooltips: {
          enabled: true
        },
        responsive: true,
        maintainAspectRatio: false
        //   animation: {
        //     duration: 500,
        //     onComplete: function() {
        //       var chartContext = this.chart.canvas.getContext("2d", {
        //         alpha: false
        //       });
        //       chartContext.font = "60px";
        //       chartContext.textBaseline = "middle";
        //       chartContext.fillText(
        //         "Siswa " + 400,
        //         this.chart.width / 2 - 30,
        //         this.chart.height / 2 - 25
        //       );
        //     }
        //   }
      }
    );
  }
};
</script>

// Vue.component("line-chart", { // extends: VueChartJs.Line, // props: ["data",
"options"], // mounted() { // this.renderLineChart(); // }, // computed: { //
chartData: function() { // return this.data; // } // }, // methods: { //
renderLineChart: function() { // this.renderChart( // { // labels: [ //
"January", // "February", // "March", // "April", // "May", // "June", // "July"
// ], // datasets: [ // { // label: "Data One", // backgroundColor: "#f87979",
// data: this.chartData // } // ] // }, // { responsive: true,
maintainAspectRatio: false } // ); // } // }, // watch: { // data: function() {
// this._chart.destroy(); // //this.renderChart(this.data, this.options); //
this.renderLineChart(); // } // } // }); // var vm = new Vue({ // el: ".app", //
data: { // message: "Hello World", // dataChart: [10, 39, 10, 40, 39, 0, 0], //
test: [4, 4, 4, 4, 4, 4] // }, // methods: { // changeData: function() { //
this.dataChart = [6, 6, 3, 5, 5, 6]; // } // } // }); // <!DOCTYPE html> //
<html>
  //
  <head>
    //
    <meta charset="utf-8" />
    //
    <meta name="viewport" content="width=device-width" />
    //
    <title>Vue.jS Chart</title>
    //
  </head>
  //
  <body>
    //
    <div class="app">
      // {{ dataChart }} //
      <button v-on:click="changeData">Change data</button> //
      <line-chart
        :data="dataChart"
        :options="{ responsive: true, maintainAspectRatio: false }"
      ></line-chart>

      //
    </div>
    //
    <script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.2.6/vue.min.js"></script>
    //
    <script src="https://unpkg.com/vue-chartjs@2.5.7-rc3/dist/vue-chartjs.full.min.js"></script>
    //
  </body>
  //
</html>
